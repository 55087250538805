<template>
  <div class="app-container">
    <el-upload
      class="upload-demo"
      accept=".xls,.xlsx"
      :action="uploadUrl"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-error="onError"
      :on-success="onSuccess"
      :before-remove="beforeRemove"
      multiple
      :limit="1"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :headers="headers"
    >
      <el-button size="small" type="primary">批量上传</el-button>
    </el-upload>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="listLoading"
      border
      fit
      highlight-current-row
      class="tableList3"
    >
      <el-table-column prop="id" label="ID" width="100px" />
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="password" label="密码"></el-table-column>
      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="getStatusTip(scope.row.status, statusMap)"
            placement="top"
          >
            <el-tag
              :type="getStatusType(scope.row.status, statusMap)"
              disable-transitions
            >
              {{ getStatusLabel(scope.row.status, statusMap) }}
            </el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="绑定手机"></el-table-column>

      <el-table-column prop="email" label="登录邮箱"></el-table-column>
      <el-table-column prop="email_password" label="邮箱密码"></el-table-column>
      <el-table-column
        prop="two_step_key"
        label="两步验证秘钥"
      ></el-table-column>
      <el-table-column prop="backup_code" label="备用码"></el-table-column>

      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column prop="update_time" label="更新时间"></el-table-column>
      <el-table-column prop="create_time" label="入库时间"></el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="removeKeyword(scope.row.id)"
            type="text"
            size="small"
          >
            删除关键词
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageIndex"
      :page-sizes="[pageSize, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { getAccountOrderList } from '@/api/twitter'
import { getCookie } from '@/plugins/axios'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
export default {
  data() {
    return {
      uploadUrl:
        process.env.VUE_APP_BASE_API + '/twitter/account_orders:batch_upload',
      fileList: [],
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      listLoading: true,
      headers: {
        'X-CSRFToken': getCookie('csrftoken')
      },
      statusMap: {
        1: {
          type: 'success',
          label: '处理成功'
        },
        0: {
          type: 'info',
          label: '待处理'
        },
        '-1': {
          type: 'danger',
          label: '处理失败'
        },
        '-2': {
          type: 'warning',
          label: '暂无可用代理'
        },
        '-3': {
          type: 'warning',
          label: '暂时无法创建配置文件'
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    // 获取服务列表
    async fetchData() {
      this.listLoading = true
      const { items, all_count: total } = await getAccountOrderList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      })
      this.tableData = items
      this.total = total
      this.listLoading = false
    },

    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.pageIndex = val
      this.fetchData()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    onError(error) {
      const response = JSON.parse(error.message)
      // this.$message.error(res.message)
      const { status, message, data } = response
      console.log(status, message, data)
      // console.log(typeof data)
      if (status === 400) {
        if (data === null || data === undefined) {
          this.$message.error(message)
        } else {
          for (const errorField in data) {
            const errorInfos = data[errorField]
            for (var errorInfoIndex in errorInfos) {
              setTimeout(() => {
                this.$message.error(
                  errorField + ': ' + errorInfos[errorInfoIndex]
                )
              }, 1)
            }
          }
        }
      } else if (status === 401) {
        // this.$alert('登录超时，请重新登录', '提示', {
        //   confirmButtonText: '确定',
        //   callback: this.$router.push('/login')
        // })
        this.$message.warning('登录超时，请重新登录')
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          this.$router.push({ path: '/login' })
        } else {
          window.location.href = origin
        }
      } else {
        this.$message.error(message)
      }
    },
    onSuccess(res) {
      const { message } = res
      if (res.code === 201) {
        this.$message.success(message)
        this.fetchData()
      } else {
        this.$message.error(res.message)
      }
      this.$refs.upload.clearFiles()
    }
  }
}
</script>

<style></style>
